export const truncateString = (str, num) => {
  if (str.length > num) {
    return `${str.slice(0, num)  }...`;
  } 
    return str;
  
}

export const getOffset = (el) => {
	const rect = el?.getBoundingClientRect();
	return rect ? {
		left: rect.left,
		top: rect.top
	} : {};
}

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object

export const composePosts = (posts, slugToExclude) => posts?.length > 0 ? posts
  .filter(node => slugToExclude ? node.frontmatter.slug !== slugToExclude : true)
  .map(node => {
    const { title, date, image, slug, type, author } = node.frontmatter;

    return {
      title,
      date,
      img: image.childImageSharp.gatsbyImageData,
      slug: `/${type}/${slug}`,
      author: author.frontmatter.name,
      authorAvatar: author.frontmatter.avatar.childImageSharp.gatsbyImageData,
      authorSlug: `/author/${author.frontmatter.slug}`,
      description: node.excerpt ? node.excerpt : '',
    }
  }) : [];