import React, { useState } from 'react';
import HeroShape1 from '../../../assets/images/hero-shape-1.svg';
import HeroShape2 from '../../../assets/images/hero-shape-2.svg';

import './Hero.scss';

const Hero = ({ title, description }) => (
    <section className="hero-adv">
      <img className='hero-adv-shape-1' src={HeroShape1} alt="Hero shape 1" />
      <img className='hero-adv-shape-2' src={HeroShape2} alt="Hero shape 2" />
      <div className='container'>
        <div className='row'>
          <div className="col">
            <div className='hero-adv-inner'>
              <div>
                <h1>{title}</h1>
                <p>{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

export default Hero;
