import React, { useState, useEffect } from 'react';
import { use100vh } from 'react-div-100vh';
import classNames from 'classnames';

import { Link } from './atoms';

import * as Routes from '../constants/routes';

import imageLogo from '../assets/images/logo/logo-sm.svg';
import IconDashboard from '../assets/images/icons/icon_dashboard.inline.svg';
import IconArrowDownBig from '../assets/images/icons/icon_arrow_down_big.inline.svg';
import IconCross from '../assets/images/icons/icon/common/close.inline.svg';
import IconArrowDown from '../assets/images/icons/icon/arrow/down.inline.svg';

const Header = () => {
  const [isOpenMobileMenu, setStateMobileMenuOpen] = useState(false);
  const height = use100vh();

  const handleStateMobileMenu = () => {
    setStateMobileMenuOpen(!isOpenMobileMenu);

    const body = document.querySelector('body');
    const html = document.querySelector('html');
    if (isOpenMobileMenu) {
      body.style.overflow = 'auto';
      body.setAttribute('style', 'overflow: auto; touch-action: auto; -ms-touch-action: auto;');
      html.setAttribute('style', 'overflow: auto;');
    } else {
      body.setAttribute(
        'style',
        'overflow: hidden; touch-action: none; -ms-touch-action: none; position: relative; height: 100%;'
      );
      html.setAttribute('style', 'overflow: hidden; position: relative; height: 100%;');
    }
  };

  useEffect(() => {
    if (!isOpenMobileMenu) {
      const body = document.querySelector('body');
      body.style.overflow = 'auto';
    }
  }, [isOpenMobileMenu]);

  return (
    <>
      <header className="header-blog">
        <div className="container">
          <div className="header-blog-inner">
            <div className="header-blog-column-left">
              <div className="header-blog-logo">
                <Link to="/">
                  <img src={imageLogo} alt="Logo" />
                </Link>
              </div>
              <nav className="header-blog-primary-nav">
                <Menu isOpenMobileMenu={isOpenMobileMenu} />
              </nav>
            </div>
            <div className="header-blog-column-right">
              <Link to="/" className="nav-link">
                <span>Homepage</span>
              </Link>
            </div>
            <div className="header-blog-mobile-btn" onClick={handleStateMobileMenu}>
              {isOpenMobileMenu ? <IconCross style={{ margin: 3 }} /> : <IconDashboard />}
            </div>
          </div>
        </div>
      </header>

      {isOpenMobileMenu && (
        <div className="header header-blog-mobile" style={{ height: `calc(${height}px - 48px)` }}>
          <div className="header__mobile">
            <div className="header__mobile__primary-nav">
              <nav className="primary-nav">
                <MenuMobile isOpenMobileMenu={isOpenMobileMenu} />
              </nav>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const MENU_DATA = [
  {
    name: 'Jetic',
    link: Routes.Platform,
  },
  {
    name: 'Docs',
    link: 'https://docs.jetic.io/docs/',
  },
  {
    name: 'Blog',
    link: Routes.Blog,
  },
  // {
  //   name: 'Product',
  //   link: Routes.Product,
  // },
];

const MenuItem = ({ data: { name, link } }) => (
  <li>
    <Link to={link} className="nav-link">
      <span>{name}</span>
    </Link>
  </li>
);

const Menu = ({ isOpenMobileMenu }) => (
  <ul>
    {MENU_DATA.map((rootItem, rootIndex) => (
      <MenuItem data={rootItem} key={rootIndex} />
    ))}
  </ul>
);

const MenuMobile = () => (
    <ul>
      {MENU_DATA.map((rootItem, rootIndex) => (
        <li
          className={classNames('primary-nav__item')}
          key={rootIndex}
        >
          <Link to={rootItem.link} className="nav-link">
            {rootItem.name}
          </Link>
        </li>
      ))}
    </ul>
  );

export default Header;
