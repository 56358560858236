import React from 'react';

import Popup from '../../atoms/Popup';

const PopupNewsletterSubscription = React.lazy(() => import('../../atoms/Popup/PopupNewsletterSubscription'));

const defaultSubscribeButton = <button className="c-btn c-btn--primary">Subscribe to our newsletter</button>;

const NewsletterSubscription = ({ trigger = defaultSubscribeButton }) => (
  <Popup
    modal
    className="popup--widthSmall"
    trigger={trigger}
  >
    {(close) =>
      typeof window !== 'undefined' &&
      typeof document !== 'undefined' && (
        <React.Suspense fallback={<div className="py-5">Loading...</div>}>
          <PopupNewsletterSubscription close={close} />
        </React.Suspense>
      )
    }
  </Popup>
);

export default NewsletterSubscription;
