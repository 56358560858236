import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import './Posts.scss';

import NewsletterSubscription from '../Subscription/NewsletterSubscription';

const Author = ({ data, subscribe }) => data ? (
    <div className={`author-adv ${subscribe ? 'subscribe' : ''}`}>
      <div className="author-adv-img">
        <GatsbyImage image={data.avatar} alt="" />
        <div>
          <h2>{data.name}</h2>
          <p>{data.role}</p>
        </div>
      </div>
      <div className="author-adv-inner">
        {subscribe ? (
          <NewsletterSubscription />
        ) : (
          <div className="author-adv-description">{data.bio}</div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );

export default Author;
