import React from 'react';
import Post from './Post';
import RecentPost from './RecentPost';
import Author from './Author'

import './Posts.scss';

const Posts = ({ posts, author, rowPosts, hasNextPage, loadMoreHandler }) => (
    <section className="posts-adv">
      <div className='container'>
        {author &&
          <div className="row">
            <div className="col">
              <Author data={author} />
            </div>
          </div>
        }
        {!author &&
          <div className="row">
            <div className="col">
              <RecentPost data={posts.slice(0, 1)[0]} />
            </div>
          </div>
        }
        <div className="row mt-5">
          {posts.slice(author ? 0 : 1).map((post) => (
            <div key={`pi-${post.slug}`} className={`${rowPosts ? 'col-md-12' : 'col-md-4'} pb-g`}>
              <Post rowPosts={rowPosts} data={post} />
            </div>
          ))}
        </div>
        {hasNextPage === true && (
          <div className="row">
            <div className="col d-flex justify-content-center">
              <button className='c-btn c-btn--primary' onClick={loadMoreHandler}>More posts</button>
            </div>
          </div>
        )}
      </div>
    </section>
  );

export default Posts;
