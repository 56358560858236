import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"

import { Link } from "../../atoms";
import { IconCalendar } from "../../Icon"
import {truncateString} from "../../../utils"

import './Posts.scss';

const Post = ({ data, rowPosts, disableDescription, small }) => (
    <div className={`post-adv ${small ? 'small' : ''} ${rowPosts ? 'row-post' : ''}`}>
      {data &&
        <div>
          <div className="post-adv-img">
            <Link to={data.slug}>
              <GatsbyImage image={data.img} objectFit="cover" alt="" />
            </Link>
            {!small &&
              <div className='post-adv-date'>
                <IconCalendar />
                {data.date}
              </div>
            }
          </div>
          <div className='post-adv-inner'>
            <Link to={data.slug}>
              <h2>{small ? truncateString(data.title, 44) : data.title}</h2>
            </Link>
            <div className='post-adv-row'>
              <Link to={data.authorSlug} className='post-adv-author'>
                <GatsbyImage image={data.authorAvatar} width={56} alt="" />
                <span>by {data.author}</span>
              </Link>
            </div>
            {!disableDescription &&
              <div className='post-adv-description'>
                {data.description}
              </div>
            }
          </div>
        </div>
      }
    </div>
  );

export default Post;
