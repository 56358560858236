import React from "react"

export const IconCalendar = () => (
  <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 2.66671H11.3334V1.33337H10V2.66671H6.00004V1.33337H4.66671V2.66671H2.00004C1.63185 2.66671 1.33337 2.96518 1.33337 3.33337V6.66671H14.6667V3.33337C14.6667 2.96518 14.3682 2.66671 14 2.66671ZM14.6667 8.00004H1.33337V14C1.33337 14.3682 1.63185 14.6667 2.00004 14.6667H14C14.3682 14.6667 14.6667 14.3682 14.6667 14V8.00004Z" />
  </svg>
)

export const IconFacebook = () => (
  <svg fill="currentColor" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 10.5 20">
    <path d="M7,11.5h2.5l1-4H7v-2c0-1,0-2,2-2h1.5V0.1C10.2,0.1,8.9,0,7.6,0C4.9,0,3,1.7,3,4.7v2.8H0v4h3V20h4V11.5z" />
  </svg>
)

export const IconLinked = () => (
  <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.335 15.339H12.67V11.162C12.67 10.166 12.65 8.884 11.28 8.884C9.891 8.884 9.679 9.968 9.679 11.089V15.339H7.013V6.75H9.573V7.92H9.608C9.966 7.246 10.836 6.533 12.136 6.533C14.836 6.533 15.336 8.311 15.336 10.624V15.339H15.335ZM4.003 5.575C3.79956 5.57526 3.59806 5.53537 3.41006 5.45761C3.22207 5.37984 3.05127 5.26574 2.90746 5.12184C2.76365 4.97793 2.64965 4.80706 2.57201 4.61901C2.49437 4.43097 2.4546 4.22944 2.455 4.026C2.4552 3.71983 2.54618 3.4206 2.71644 3.16615C2.8867 2.91169 3.12859 2.71343 3.41153 2.59645C3.69447 2.47947 4.00574 2.44902 4.30598 2.50894C4.60622 2.56886 4.88196 2.71648 5.09831 2.93311C5.31466 3.14974 5.46191 3.42566 5.52145 3.72598C5.58099 4.0263 5.55013 4.33753 5.43278 4.62032C5.31543 4.9031 5.11687 5.14474 4.86219 5.31467C4.60751 5.4846 4.30817 5.5752 4.002 5.575H4.003ZM5.339 15.339H2.666V6.75H5.34V15.339H5.339ZM16.67 0H1.329C0.593 0 0 0.58 0 1.297V16.703C0 17.42 0.594 18 1.328 18H16.666C17.4 18 18 17.42 18 16.703V1.297C18 0.58 17.4 0 16.666 0H16.669H16.67Z" />
  </svg>
)

export const IconTwitter = () => (
  <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20.4 16.6" >
    <path d="M20.4,2c-0.8,0.3-1.6,0.6-2.4,0.7c0.9-0.5,1.5-1.3,1.8-2.3c-0.8,0.5-1.7,0.8-2.7,1c-0.6-0.7-1.5-1.1-2.4-1.3c-0.9-0.2-1.8,0-2.7,0.4c-0.8,0.4-1.5,1.1-1.8,2C9.9,3.3,9.8,4.2,10,5.1C8.4,5.1,6.7,4.6,5.3,3.9C3.8,3.1,2.5,2.1,1.4,0.8C1,1.4,0.9,2.1,0.9,2.9c0,1.4,0.7,2.7,1.9,3.5C2,6.3,1.4,6.2,0.8,5.8v0.1c0,1,0.3,1.9,0.9,2.6C2.4,9.3,3.2,9.8,4.2,10c-0.6,0.2-1.3,0.2-1.9,0.1c0.3,0.8,0.8,1.6,1.5,2.1c0.7,0.5,1.5,0.8,2.4,0.8c-0.9,0.7-1.9,1.2-2.9,1.5c-1.1,0.3-2.2,0.4-3.3,0.2c1.9,1.2,4.1,1.9,6.4,1.9c7.7,0,11.9-6.4,11.9-11.9c0-0.2,0-0.4,0-0.5C19.1,3.5,19.8,2.8,20.4,2L20.4,2z" />
  </svg>
)