import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"

import calendar from "../../../assets/images/icons/calendar.svg"
import { Link } from "../../atoms";
import './Posts.scss';

const RecentPost = ({ data }) => (
    <div to={data.slug} className="recent-post-adv">
      <Link to={data.slug} className="recent-post-adv-img">
        <GatsbyImage image={data.img} alt="" />
      </Link>
      <div className='recent-post-adv-inner'>
        <Link to={data.slug}>
          <h2>{data.title}</h2>
        </Link>
        <div className='recent-post-adv-row'>
          <Link to={data.authorSlug} className='recent-post-adv-author'>
            <GatsbyImage image={data.authorAvatar} width={56} alt="" />
            by {data.author}
          </Link>
          <div className='recent-post-adv-date'>
            <img src={calendar} alt="calendar" />
            {data.date}
          </div>
        </div>
        <div className='recent-post-adv-description'>
          {data.description}
        </div>
      </div>
    </div>
  );

export default RecentPost;
