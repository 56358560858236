import React from 'react';
import { Helmet } from 'react-helmet';

import SEO from './SEO';
import HeaderBlog from './HeaderBlog';
import Footer from './Footer';

// Styles
import '../assets/styles/main.scss';

const LayoutBlog = ({ children, title }) => (
    <>
      <SEO title={title} />

      <HeaderBlog />
      <div className="root-blog">{children}</div>
      <Footer />
    </>
  );

export default LayoutBlog;
